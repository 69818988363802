import { useEffect, useState } from "react";
import { Row, Col, Drawer, Modal } from "antd";
import { withTranslation, TFunction } from "react-i18next";
import { DownOutlined } from "@ant-design/icons"; // Import de l'icône de flèche vers le bas
import Container from "../../common/Container";
import { SvgIcon } from "../../common/SvgIcon";
import { Button } from "../../common/Button";
import { HeaderSection, LogoContainer, Burger, NotHidden,Menu, CustomNavLinkSmall,Label, Outline, Span, } from "./styles";
import "./style.css"
import { useHistory } from "react-router-dom";
import config from "../../config";
import axios from "axios";

const Header = ({ t }: { t: TFunction }) => {
  const [visible, setVisibility] = useState(false);
  const [photoProfilUrl, setPhotoProfilUrl] = useState("");

  const rolelog = sessionStorage.getItem('userRole');
  const idWplog = sessionStorage.getItem('userIdWp');

  const toggleButton = () => {
    setVisibility(!visible);
  };

  const MenuItem = () => {
    const scrollTo = (id: string) => {
      const element = document.getElementById(id) as HTMLDivElement;
      element.scrollIntoView({
        behavior: "smooth",
      });
      setVisibility(false);
    };

    const history = useHistory();
const onCharge = async () => {
  const urlRequete0 = `${config.API_URL}/profil.php`;

  const response = await axios.post(urlRequete0, {
        Id_WP: idWplog,
        Method:'get',
    }, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded', // Modifier le type de contenu ici
        }
    });
    console.log('response 105');
    console.log(response);
    
    if(response.data.status == 1 || response.data.status == '1'){
     
      if(sessionStorage.getItem('photoProfilUrl')){
        setPhotoProfilUrl(sessionStorage.getItem('photoProfilUrl') ?? '006');
      }
      
      // console.log('photoProfilUrl : ', photoProfilUrl)
    }
    else{
      // photoProfilUrl = sessionStorage.getItem('photoProfilUrl') ?? '002';
      setPhotoProfilUrl(sessionStorage.getItem('photoProfilUrl') ?? '002');
    }
}

useEffect(() => {
  const userIdWp = sessionStorage.getItem('userIdWp');
  if (!userIdWp) {
    // history.push('/');
  } else {
    onCharge();
  }
}, [history]);

    return (
      <>
        <CustomNavLinkSmall>
          <Span id="menu-rechercher"><a href="/rechercher">{t("Rechercher un trajet")}</a></Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall>
          <Span id="menu-proposer"><a href="/proposer">{t("Proposer un trajet")}</a></Span>
        </CustomNavLinkSmall>
        {idWplog ? (
          <CustomNavLinkSmall className="menuProfil">
            <Span id="menu-profil">
              <a href={`/profil`}>
              
              {photoProfilUrl ?
                                <img src={photoProfilUrl} alt={"profil"} width={"100px"} height={""} /> :
                              <SvgIcon
                                src= {photoProfilUrl ?? "logo512.png"}
                                aria-label="homepage"
                                width="100px"
                                height="100px"
                              />}
              
              <DownOutlined  id="sousMenu"/></a>
            </Span>
            <ul className="submenu">
              <li>
                <CustomNavLinkSmall>
                  <Span id="menu-sous-menu1"><a href="/dashboard">{t("Mon tableau de bord")}</a></Span>
                </CustomNavLinkSmall>
              </li>
              <li>
                <CustomNavLinkSmall>
                  <Span id="menu-sous-menu2"><a href="/mes-trajets-futur-cond">{t("Mes trajets")}</a></Span>
                </CustomNavLinkSmall>
              </li>
              <li>
                <CustomNavLinkSmall>
                  <Span id="menu-sous-menu1"><a href="/profil">{t("Mon profil")}</a></Span>
                </CustomNavLinkSmall>
              </li>
              <li>
                <CustomNavLinkSmall>
                  <Span id="menu-sous-menu2"><a href="/mon-solde">{t("Mon Kpakpato money")}</a></Span>
                </CustomNavLinkSmall>
              </li>
              <li>
                <CustomNavLinkSmall>
                  <Span id="menu-sous-menu1"><a href="/messagerie">{t("Messagerie")}</a></Span>
                </CustomNavLinkSmall>
              </li>
              <li>
                <CustomNavLinkSmall>
                  <Span id="menu-sous-menu2"><a href="/mes-trajets-annule-cond">{t("Mes annulations")}</a></Span>
                </CustomNavLinkSmall>
              </li>
              <li>
                <CustomNavLinkSmall onClick={showLogoutModal}>
                  <Span id="menu-sous-menu1">{t("Se déconnecter")}</Span>
                </CustomNavLinkSmall>
              </li>
            </ul>
          </CustomNavLinkSmall>
        ) : (
          <>
            <CustomNavLinkSmall>
              <Span id="menu-inscription"><a href="/inscription">{t("Inscription")}</a></Span>
            </CustomNavLinkSmall>
            <CustomNavLinkSmall>
              <Span id="menu-connexion"><a href="/connexion">{t("Connexion")}</a></Span>
            </CustomNavLinkSmall>
          </>
        )}
      </>
    );
  };

  
  const [isModalVisible, setIsModalVisible] = useState(false);
  
  const showLogoutModal = () => {
    setIsModalVisible(true);
  };

  const handleLogout = () => {
    // Logique de déconnexion ici
    alert("Vous êtes déconnecté.");
    sessionStorage.removeItem('userIdWp');
    sessionStorage.removeItem('userRole');
    sessionStorage.removeItem('userEmail');
    window.location.href = '/';
    console.log("Déconnecté");
    console.log("userIdWp", sessionStorage.getItem('userIdWp'));
    console.log("userRole", sessionStorage.getItem('userRole'));
    console.log("userEmail", sessionStorage.getItem('userEmail'));
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <HeaderSection>
      <Container>
        <Row justify="space-between">
          <LogoContainer to="/" aria-label="homepage">
            <SvgIcon src="logo512.png" width="101px" height="64px" />
          </LogoContainer>
          <NotHidden>
            <MenuItem />
          </NotHidden>
          <Burger onClick={toggleButton}>
            <Outline />
          </Burger>
        </Row>
        <Drawer closable={false} open={visible} onClose={toggleButton}>
          <Col style={{ marginBottom: "2.5rem" }}>
            <Label onClick={toggleButton}>
              <Col span={12}>
                <Menu>Menu</Menu>
              </Col>
              <Col span={12}>
                <Outline />
              </Col>
            </Label>
          </Col>
          <MenuItem />
        </Drawer>
      </Container>
      <Modal
        title="Confirmation de déconnexion"
        visible={isModalVisible}
        onOk={handleLogout}
        onCancel={handleCancel}
        okText="Oui"
        cancelText="Non"
      >
        <p>Êtes-vous sûr de vouloir vous déconnecter ?</p>
      </Modal>
    </HeaderSection>
  );
};

export default withTranslation()(Header);
