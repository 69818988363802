const config = { 
    //http://localhost/KKCBACKEND 
    //API_URL: "https://kkcbackend.monespacetest.fr/backend",
     // API_URL: "backend.workforall.monespacetest.fr ",
    API_URL: "https://backendkkc.monespacetest.fr/backend",
    //API_URL: "hhttp://localhost/KKCBACKEND",
    FEDAPAY_PUBLIC_KEY: 'pk_sandbox_jttItCozzjgBbPPWMPRaNuSh',
    FEDAPAY_SECRET_KEY: 'sk_sandbox_HyjvIoBlGNh4Yyt2qxje5b44',

};

export default config; 
