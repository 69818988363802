import { Row, Col } from "antd";
import { withTranslation, TFunction } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";
import i18n from "i18next";
import {
  FooterSection,
  NavLink,
  Extra,
  SocialLinkContainer,
  CopyrightText,
} from "./styles";

const Footer = ({ t }: { t: TFunction }) => {
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const SocialLink = ({ href, src }: { href: string; src: string }) => (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      aria-label={src}
      style={{ color: "#EBE5DF" }}
    >
      {/* <SvgIcon src={src} width="25px" height="25px"/> */}
      <img src={`/img/svg/${src}`} alt={src} width="25px" height="25px" />
    </a>
  );

  const currentYear = new Date().getFullYear();

  return (
    <FooterSection>
      <Container>
        <Row justify="space-between">
          <Col xs={24} sm={12} md={8} lg={6}>
            {/* <h4>{t("Navigation")}</h4> */}
            <NavLink to="/comment-ca-marche">{t("Comment ça marche?")}</NavLink>
            <NavLink to="/foire-aux-questions">{t("Foire aux questions")}</NavLink>
            <NavLink to="/infos-legales">{t("Informations légales")}</NavLink>
          </Col>

          <Col xs={24} sm={12} md={8} lg={6}>
            {/* <h4>{t("Entreprise")}</h4> */}
            <NavLink to="/qui-sommes-nous">{t("Qui sommes-nous?")}</NavLink>
            <NavLink to="/contact">{t("Contact")}</NavLink>
            <NavLink to="/nous-recrutons">{t("Nous recrutons")}</NavLink>
          </Col>

          <Col xs={24} sm={24} md={8} lg={6}>
            {/* <h4>{t("Suivez-nous")}</h4> */}
            <SocialLinkContainer>
              <SocialLink href="https://linkedin.com" src="linkedin.svg" />
              <SocialLink href="https://twitter.com" src="twitter.svg" />
              <SocialLink href="https://facebook.com" src="facebook.svg" />
              <SocialLink href="https://instagram.com" src="instagram.svg" />
              <SocialLink href="https://tiktok.com" src="tiktok.svg" />
            </SocialLinkContainer>
          </Col>
        </Row>
        <Extra>
          <CopyrightText>
            {`Kpakpato Car ${currentYear} ©. Tous droits réservés.`}
          </CopyrightText>
        </Extra>
      </Container>
    </FooterSection>
  );
};

export default withTranslation()(Footer);