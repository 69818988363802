import styled from "styled-components";
import { Link } from "react-router-dom";

export const FooterSection = styled("footer")`
  background-color: #00143C;
  color: #EBE5DF;
  padding: 2rem 0;
`;

export const NavLink = styled(Link)`
  display: block;
  color: #EBE5DF;
  margin-bottom: 0.5rem;
  text-decoration: none;
  transition: color 0.3s;

  &:hover {
    color: #7ED957;
  }
`;

export const Extra = styled("div")`
  text-align: center;
  margin-top: 2rem;
  padding-top: 1rem;
  border-top: 1px solid #97A8B6;
`;

export const SocialLinkContainer = styled("div")`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;

  a {
    transition: transform 0.3s;

    &:hover {
      transform: scale(1.1);
    }

    svg {
      fill: #FF5C5C;
    }
  }
`;

export const CopyrightText = styled("p")`
  color: #F9E373;
  font-size: 0.9rem;
  margin: 0;
`;
