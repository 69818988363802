const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
  },
  {
    path: ["/", "/inscription"],
    exact: true,
    component: "Inscription",
  },
  {
    path: ["/", "/connexion"],
    exact: true,
    component: "Connexion",
  },
  {
    path: ["/", "/comment-ca-marche"],
    exact: true,
    component: "CommentCaMarche",
  },
  {
    path: ["/", "/foire-aux-questions"],
    exact: true,
    component: "Foire",
  },
  {
    path: ["/", "/infos-legales"],
    exact: true,
    component: "Info",
  },
  {
    path: ["/", "/proposer"],
    exact: true,
    component: "ProposerTrajet",
  }, 
  {
    path: ["/", "/profil"],
    exact: true,
    component: "Profil",
  },
  {
    path: ["/", "/qui-sommes-nous"],
    exact: true,
    component: "WhoAmI",
  },
  {
    path: ["/", "/preferences"],
    exact: true,
    component: "Preference",
  },
  {
    path: ["/", "/vehicule"],
    exact: true,
    component: "Vehicule",
  },
  
  {
    path: ["/", "/avis-recus"],
    exact: true,
    component: "AvisRecu",
  },
  {
    path: ["/", "/avis-envoyes"],
    exact: true,
    component: "AvisRecu",
  },
  {
    path: ["/", "/parrainage"],
    exact: true,
    component: "Parrainage",
  },
  {
    path: ["/", "/fidelite"],
    exact: true,
    component: "Fidelite",
  },
  
  {
    path: ["/", "/dashboard"],
    exact: true,
    component: "Dashboard",
  },
  {
    path: ["/", "/mes-trajets-futur-cond"],
    exact: true,
    component: "MesTrajetsFuturCond",
  },
  {
    path: ["/", "/mes-trajets-passe-cond"],
    exact: true,
    component: "MesTrajetsPassesCond",
  },
  {
    path: ["/", "/mes-trajets-annule-cond"],
    exact: true,
    component: "MesTrajetsAnnulesCond",
  },
  {
    path: ["/", "/mes-trajets-futur-pass"],
    exact: true,
    component: "MesTrajetsFuturPass",
  },
  {
    path: ["/", "/mes-trajets-passe-pass"],
    exact: true,
    component: "MesTrajetsPassesPass",
  },
  {
    path: ["/", "/mes-trajets-annule-pass"],
    exact: true,
    component: "MesTrajetsAnnulesPass",
  },
  {
    path: ["/", "/mes-trajets-futur-passautr"],
    exact: true,
    component: "MesTrajetsFuturPassAutr",
  },
  {
    path: ["/", "/mes-trajets-passe-passautr"],
    exact: true,
    component: "MesTrajetsPassesPassAutr",
  },
  {
    path: ["/", "/mes-trajets-annule-passautr"],
    exact: true,
    component: "MesTrajetsAnnulesPassAutr",
  },
  {
    path: ["/", "/mon-solde"],
    exact: true,
    component: "MonSoldeKKM",
  },

];

export default routes;
